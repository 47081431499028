import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When people ask me how I build user interfaces these days they often stare back
at me with wide-eyed shock when I tell them I manage all my state in a single
tree using Flux to populate it.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Wait a minute, you store everything in a single tree? Like one giant variable?`}</p>
    </blockquote>
    <p>{`Yup. And it's awesome.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Really?`}</p>
    </blockquote>
    <p>{`Totes McGoats`}</p>
    <h2>{`What is the "single state tree" anyways?`}</h2>
    <p>{`In order for me to make my audacious claims of why I think this approach is
awesome, let me first explain the approach. It's really quite simple, all of
your application's state is managed in a single tree. The tree is populated
using a concept called "actions".`}</p>
    <h3>{`What are actions?`}</h3>
    <p>{`Actions are serialized representations of events that get dispatched your
"store", your stores job is to manage that single state tree. Some examples of
actions:`}</p>
    <p>{`The `}<em parentName="p">{`results`}</em>{` of an HTTP request:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FETCH_COMMENTS_SUCCESS'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  payload`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    comments`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`A user interaction:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"SAVE_COMMENT"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Or any other type of state changing mechanism.`}</p>
    <h3>{`What is a store?`}</h3>
    <p>{`Your store receives each action and figures out what state to derive from it.
For example, the system make a request to get the latest comments, it then
dispatches an action, the store then says:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Ahhh, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`FETCH_COMMENTS_SUCCESS`}</code>{`, I know precisely what to do with you, I save
your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`comments`}</code>{` into cache and notify my observers that state has changed.`}</p>
    </blockquote>
    <p>{`So what does this process look like for a typical "Download and Show Data"
requirement? Well first we have what we call an "action creator" which is really
just a function that creates an action object, or a serialized representation of
a state change, and dispatches it to the store. So it all starts with an action
creator:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getComments`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`dispatch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  $`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`ajax`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    url`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/post/single-state-tree/comments"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`comments`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` action `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"FETCH_COMMENTS_SUCCESS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      payload`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        comments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`dispatch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`We then have a store with a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dispatch`}</code>{` function that manages all state changes.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`dispatch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`type `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FETCH_COMMENTS_SUCCESS'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Aww yes my good sir, I know precisely what to do with you.`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Set next state...`}</span>{`
    state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Object`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`assign`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` comments`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`comments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`These two are then combined accordingly to dispatch the action.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`getComments`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`dispatch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Typically dispatch is associated with a store, I'll get into that in just a bit.
Anyways, subscribers to state changes are then notified, "Hey Miss, your state
looks different!"`}</p>
    <h3>{`What is a single state tree?`}</h3>
    <p>{`A single state tree is a single tree that represents your state. This approach
is different than traditional flux in that you have a singular store which
manages all of your states in one mega tree. First, though let's recap some of
the benefits of Flux.`}</p>
    <h2>{`Benefits of Flux`}</h2>
    <ol>
      <li parentName="ol">{`Using actions to represent state changes provides a serializable data format
for all your systems state changes.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Ever wondered how your application got into a particular state? Wonder no
more, we now have a frame by frame replay of every state change your system
has ever experienced.`}</li>
      <li parentName="ul">{`This frame by frame replay is shareable, imagine a bug report with a
downloadable "reproduce" file. You simply `}<em parentName="li">{`play`}</em>{` the "reproduce" file and get
the same bug.`}</li>
      <li parentName="ul">{`This frame by frame replay is analyzable. Imagine recording all your user
tests and using analysis tools to get deeper insights into how people interact
with your system. Also, optimization opportunities anyone?`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`All of your state changes are passed through a single mechanism.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`A developer can walk into a system and know every potential state changing
piece of code there is by looking at your action's constants.`}</li>
      <li parentName="ul">{`Developers can author "middleware", or functions that each action is passed
through. This enables things like logging each state change, having different
types of actions such as thunks or promises, implementing analytics, the
possibilities are only as limited as our creativity.`}</li>
      <li parentName="ul">{`Developer tooling hook into every state change, holy awesome.`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Synchronous UI`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`UI is rendered synchronously, every time. No more this state change caused
this state change, caused this state change, caused this state change. Just
here is the current state, what does the UI look like with this state? Just
think about it, your UI as a pure function... ever heard of UI so easy to
test? Me either.`}</li>
    </ul>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">
        <p parentName="li">{`Decouples actions from state changes. This means you can have one action
make multiple state changes. For example, HTTP responses are decoupled from
state changes. This is useful for endpoints that contain nested entities.
For example, say you download all of the comments and they come back with
nested user objects, with flux you can simply populate the user section of
the tree, next time someone asks for that user, you can skip the request
because you know you have the sufficient state.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The questions, "What is my state?" & "When does my state change?" is
answered simply instead of being littered throughout your application code.`}</p>
      </li>
    </ol>
    <p>{`Ok, that all sounds great but at what cost! I hear you gasping...`}</p>
    <blockquote>
      <p parentName="blockquote">{`But, but, but this approach seems unwieldy and infeasible.`}</p>
    </blockquote>
    <p>{`Does it, dear friend? It's not. There are tools to rope in the hard parts. Here
is an example of my tool of choice for leveraging this technique, called Redux.`}</p>
    <h1>{`Redux`}</h1>
    <p>{`Redux has a remarkably simple interface. And its job is solving precisely what
this article is all about, a single state tree managed by actions. Let's start
out by making a store, in redux this function is called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createStore`}</code>{`.`}</p>
    <h3>{`Creating a Store`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` createStore `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"redux"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` store `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createStore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`handler`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`When we call `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`createStore`}</code>{` we give it our action handler or our `}<em parentName="p">{`reducer`}</em>{`. The
reducers job is to take the current state and the action and return the next
state. Following our example above let's write a simple reducer.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`reducer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`state `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`type `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"FETCH_COMMENTS_SUCCESS"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` Object`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`assign`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      comments`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`comments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` store `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createStore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reducer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Writing a reducer like this can get a little unwieldy because you are managing
the structure of your tree yourself, thankfully Redux offers a little utility
function called combineReducers, its job is to take multiple reducers and manage
the tree structure for you. It can be as nested as you like but we'll
demonstrate a flat tree below.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` reducerTree `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  comments`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`type `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'FETCH_COMMENTS_SUCCESS'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` action`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`comments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  users`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  posts`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` store `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`createStore`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`combineReducers`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reducerTree`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Now our comments reducer is just the reducer for comments, our user reducer is
just the reducer for users etc.. Our state tree would look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  comments`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  users`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  posts`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Notice how it matches the reducerTree we provided to combineReducers?`}</p>
    <p>{`Since we have our reducer wired up when we call `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dispatch`}</code>{` on the store with
that particular action, the store's state tree will change to a copied state
tree with the comments included. But how do we access this state tree? Well its
pretty simple really, we call `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getState`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` store`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`This can be called at any point in time to retrieve your applications current
state but you typically call it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onSubscribe`}</code>{`. Which lets you listen for changes
to the state and do something accordingly, you know, like render your interface
again.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`store`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`subscribe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` state `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` store`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`render`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ui`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` state`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`This gives you simple, `}<em parentName="p">{`synchronous`}</em>{` renders, where each render is not a mix of
changes over time but a singular snapshot of state at a given point in time.`}</p>
    <h3>{`Benefits of Single State Tree`}</h3>
    <ol>
      <li parentName="ol">{`Improved developer tooling. Since all your state exists in one location,
other parts of your application can be reloaded `}<em parentName="li">{`without`}</em>{` blasting your
state. Gone are the days of reloading your entire application, clicking
different things to get your application into that state you are working on
and testing your changes. Instead, you simply reload that one file and your
application's state stays intact. You can do this kind of hot reloading with
different tools in the ecosystem.`}</li>
      <li parentName="ol">{`Shared cache across your system. When one section of your application
downloads a user, its there for the other pieces of your application that
use that user, no HTTP request required.`}</li>
      <li parentName="ol">{`Your entire applications state can be viewed in one structure (and shared as
one structure) see benefits of Flux above.`}</li>
      <li parentName="ol">{`The majority of your applications state management are pure functions, hello
testability.`}</li>
      <li parentName="ol">{`Your applications state can be simply bootstrapped from the server, hello
server-side rendering.`}</li>
      <li parentName="ol">{`State changes are predictable.`}</li>
      <li parentName="ol">{`Undo & Redo are practically free.`}</li>
    </ol>
    <h2>{`Further Reading`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://rackt.github.io/redux/index.html"
        }}>{`Redux Documentation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/gaearon/react-hot-loader"
        }}>{`React Hot Reloader`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/gaearon/redux-devtools"
        }}>{`Redux Developer Tools`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      